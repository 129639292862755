import React from "react";
import { Box, Typography } from "@mui/material";
import * as Icons from "@mui/icons-material";

import QuestionField from "../components/formSection/QuestionField";
import BlockView from "../components/formSection/BlockView";
import { geFlexSettingValue } from "../utils/dataUtils";

const FormSection = ({
  questions,
  activeStep,
  steps,
  formValues,
  setQuestionAnswer,
  setUnitAnswer,
  isDataModified,
  setIsDataModified,
  handleAutoFill,
  isFieldAutoFilling,
}) => {
  if (!questions || questions.length === 0 || "error" in questions) return null;
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;
  const areaId = area.id;
  const blocks = area.blocks;

  const blockToQuestions = {};
  const blockMasterQuestionIds = new Set();

  blocks?.forEach((block) => {
    blockToQuestions[block.id] = questions.filter((question) =>
      block.master_questions?.find((mq) => mq.id === question.id)
    );

    blockToQuestions[block.id]?.forEach((question) => {
      blockMasterQuestionIds.add(question.id);
    });
  });

  const areaQuestions = questions.filter(
    (question) =>
      question.area_id === areaId && !blockMasterQuestionIds.has(question.id)
  ); // eslint-disable-line eqeqeq

  const Icon = Icons[area.icon] || Icons.InfoOutlined;

  const sortedAreaQuestions = areaQuestions.sort((a, b) => {
    if (a.ordering_number === null && b.ordering_number === null) return 0; // Keep original order if both are null
    if (a.ordering_number === null) return 1; // a should come after b if a is null
    if (b.ordering_number === null) return -1; // b should come after a if b is null
    return a.ordering_number - b.ordering_number; // normal numeric sort
  });

  const renderItems = [];

  // Add area (standalone) questions
  sortedAreaQuestions?.forEach((question) => {
    renderItems.push({
      type: "question",
      data: question,
      ordering_number: question.ordering_number ?? Infinity, // null-safe sorting
    });
  });

  // Add blocks
  blocks?.forEach((block) => {
    renderItems.push({
      type: "block",
      data: block,
      ordering_number:
        geFlexSettingValue(block?.settings, "ordering_number", "int") ??
        Infinity,
    });
  });

  // Sort all items by ordering_number
  renderItems.sort((a, b) => a.ordering_number - b.ordering_number);

  return (
    <>
      <form>
        <Box display="flex" alignItems="center">
          <Icon color="primary" />
          <Typography variant="h5" color="primary" style={{ padding: "10px" }}>
            {area?.label || area?.name || "Unavngivet fane"}
          </Typography>

          <Box
            sx={{
              marginLeft: "auto",
              backgroundColor: "primary.main",
              color: "white",
              px: 2,
              py: 0.5,
              borderRadius: 2,
              display: { xs: "block", sm: "none" },
            }}
          >
            <Typography variant="body1">
              {activeStep + 1}/{steps.length}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ marginBottom: 5 }}
        >
          {area.description}
        </Typography>
        {renderItems.map((item, index) => {
          if (item.type === "question") {
            const question = item.data;
            let questionValue;
            if (
              (question.format === "text" ||
                question.format === "single-line text") &&
              formValues[`value_${question.id}`].value === undefined
            ) {
              questionValue = question.template_text;
            } else {
              questionValue = formValues[`value_${question.id}`].value ?? "";
            }

            const units = question.units;
            let questionUnit = units?.find(
              (u) => u.id === Number(formValues[`unit_${question.id}`].value)
            );
            if (questionUnit === undefined) {
              questionUnit = question.default_unit;
            }

            return (
              <Box
                key={`question-${question.id}`}
                sx={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  scrollbarWidth: "none",
                  paddingTop: 0.6,
                }}
              >
                <QuestionField
                  question={question}
                  formValue={questionValue}
                  questionUnit={questionUnit}
                  isAutoFilling={isFieldAutoFilling[question.id] || false}
                  setQuestionAnswer={setQuestionAnswer}
                  setUnitAnswer={setUnitAnswer}
                  handleAutoFill={handleAutoFill}
                />
              </Box>
            );
          }

          if (item.type === "block") {
            const block = item.data;
            const masterQuestions = blockToQuestions[block.id];
            return (
              <BlockView
                key={`block-${block.id}`}
                block={block}
                questions={masterQuestions}
                formValues={formValues}
                setQuestionAnswer={setQuestionAnswer}
                setUnitAnswer={setUnitAnswer}
                handleAutoFill={handleAutoFill}
                isFieldAutoFilling={isFieldAutoFilling}
              />
            );
          }

          return null;
        })}
      </form>
    </>
  );
};

export default FormSection;

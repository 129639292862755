// src/pages/CreateCompany.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import logo from "../logo-white.jpg";
import { useSnackbar } from "../contexts/SnackbarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";
import { useUserContext } from "../contexts/UserContext";
import { useCompanyContext } from "../contexts/CompanyContext";

const CreateCompany = () => {
  const { setToken } = useAuthContext();
  const { setActiveCompanyId } = useCompanyContext();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [createCompanyData, setCreateCompanyData] = useState({
    name: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    phone: "",
    email: "",
    vat_number: "",
    bank_vendor_id: "",
    start_date: "",
    industry_code: "",
    industry_description: "",
  });
  const [createUserData, setCreateUserData] = useState({
    user_email: "",
    user_password: "",
    user_fullname: "",
    user_password_confirm: "",
  });

  const [cvrValid, setCvrValid] = useState(false);
  const [cvrError, setCvrError] = useState("");

  const [createUserDataValidErrors, setCreateUserDataValidErrors] = useState({
    user_email: "",
    user_password: "",
    user_fullname: "",
    user_password_confirm: "",
  });

  const validateFullname = (fullname) => {
    if (!fullname) return "Navn er påkrævet.";
    return "";
  };

  const validateEmail = async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return "Email adresse er påkrævet.";
    if (!emailRegex.test(email)) return "Ugyldig email adresse.";

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/users/exists?email=${email}`
      );

      const responseJson = await response.json();

      if (responseJson.exists) {
        return "Der eksisterer allerede en konto med denne email.";
      }
    } catch (err) {
      console.error(err);
      return "Der skete en uventet fejl ved verificering af email. Prøv igen eller kontakt supporten.";
    }

    return "";
  };

  const validatePassword = (password) => {
    if (!password) return "Adgangskode er påkrævet.";
    if (password.length < 8)
      return "Adgangskoden skal være mindst 8 tegn lang.";
    return "";
  };

  const validatePasswordConfirm = (password, passwordConfirm) => {
    if (!passwordConfirm) return "Bekræft adgangskode er påkrævet.";
    if (password !== passwordConfirm) return "Adgangskoderne matcher ikke.";
    return "";
  };

  const [banks, setBanks] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuthContext();
  const demoToken = searchParams.get("demo-token");
  const { userData, fetchUserData } = useUserContext();
  const [createdCompanyId, setCreatedCompanyId] = useState(null);

  const steps = ["Bruger", "CVR nummer", "Virksomhed"];

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = (event) => {
    event.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    try {
      const userResponseData = await createUser();

      if (userResponseData?.user && userResponseData?.token) {
        const companyResponseData = await createCompany(
          userResponseData.user.email
        );

        if (companyResponseData) {
          setToken(userResponseData.token);
          showSnackbar("Velkommen " + userResponseData.user.name, "success");
          navigate("/dashboard", { state: { showTutorial: true } });
        }
      } else {
        throw new Error(
          "No user object or token found",
          `userObject: ${userResponseData?.user}`,
          `token: ${userResponseData?.token}`
        );
      }
    } catch (error) {
      console.error(error);
      setError(
        "Noget gik galt i oprettelsen. Prøv igen eller kontakt venligst support."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCompany = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    try {
      if (!userData?.email) {
        return;
      }

      const companyResponseData = await createCompany(userData.email);

      if (companyResponseData) {
        if (companyResponseData.company && companyResponseData.company.id) {
          setCreatedCompanyId(companyResponseData.company.id); // ✅ Store the real company ID
        }
      }
    } catch (error) {
      console.error(error);
      setError(
        "Noget gik galt i oprettelsen. Prøv igen eller kontakt venligst support."
      );
    } finally {
      setLoading(false);
    }
  };

  const createCompany = async (user_email) => {
    const now = new Date();
    const lastYear = now.getFullYear() - 1;
    const accountingYearStartDate = new Date(lastYear, 0, 1); // January 1st of last year
    const accountingYearEndDate = new Date(lastYear, 11, 31);

    const mergedData = {
      ...(demoToken ? { demo_token: demoToken } : {}),
      ...createCompanyData,
      ...{
        user_emails: [user_email],
        accounting_year_start_date: formatDate(accountingYearStartDate),
        accounting_year_end_date: formatDate(accountingYearEndDate),
      },
      ...(user_email && { email: user_email }),
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/companies", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mergedData),
    });

    // Parse the JSON response
    const data = await response.json();

    await fetchUserData();

    return data;
  };

  const createUser = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: createUserData.user_email,
        password: createUserData.user_password,
        name: createUserData.user_fullname,
      }),
    });

    const data = await response.json();
    return data;
  };

  const handleCvrChange = useCallback(
    async (event) => {
      const value = event.target.value;
      setCreateCompanyData((prevData) => ({ ...prevData, vat_number: value }));
      if (value.length === 8) {
        fetch(process.env.REACT_APP_API_URL + "/cvrapi?cvr=" + value)
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Der opstod en fejl ved kontakt til CVR registeret: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((data) => {
            if (data && !data.error) {
              if (data.companyExists === false || !!demoToken) {
                setCreateCompanyData((prevData) => ({
                  ...prevData,
                  name: data.name,
                  address: data.address,
                  city: data.city,
                  zip: data.zipcode,
                  country: "DK",
                  phone: data.phone,
                  email: data.email,
                  vat_number: data.vat,
                  start_date: data.startdate,
                  industry_code: data.industrycode,
                  industry_description: data.industrydesc,
                }));
                setCvrValid(true);
                setCvrError("");
              } else {
                setCvrValid(false);
                setCvrError("Virksomheden er allerede oprettet hos os");
              }
            } else {
              setCvrValid(false);
              setCvrError("Virksomhed ikke fundet i CVR registeret");
            }
          })
          .catch((error) => {
            setCvrValid(false);
            setCvrError(
              "Der opstod en fejl under validering af CVR nummeret, kontakt venligst support (" +
                error.message +
                ")"
            );
          });
      } else {
        setCvrValid(false);
      }
    },
    [demoToken]
  );

  useEffect(() => {
    if (
      createdCompanyId &&
      userData?.companies?.some((c) => c.company_id === createdCompanyId)
    ) {
      setActiveCompanyId(createdCompanyId);
      showSnackbar("Velkommen " + userData?.name, "success");
      navigate("/dashboard", { state: { showTutorial: true } });
    }
  }, [userData, createdCompanyId, setActiveCompanyId, showSnackbar, navigate]);

  useEffect(() => {
    if (token) {
      setActiveStep(1);
    }
  }, [token]);

  useEffect(() => {
    const fetchBanks = async () => {
      const retries = 5;
      const delay = 200;
      try {
        for (let i = 0; i < retries; i++) {
          try {
            const response = await fetch(
              process.env.REACT_APP_API_URL + "/bankvendors",
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const data = await response.json();
            if (!Array.isArray(data) || data.length === 0) {
              throw new Error("Data is empty");
            }

            setBanks(data);
          } catch (error) {
            if (i === retries - 1) {
              throw error;
            }
            await new Promise((resolve) =>
              setTimeout(resolve, delay * Math.pow(2, i))
            );
          }
        }
      } catch (error) {
        console.error("Could not fetch bank vendors", error);
        setBanks([]);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    const setDemoInfo = async () => {
      const retries = 3;
      const delay = 200;

      try {
        for (let i = 0; i < retries; i++) {
          try {
            const response = await fetch(
              process.env.REACT_APP_API_URL +
                "/demo-token-info?demo-token=" +
                demoToken,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const data = await response.json();

            if (response.ok && data.vat_number) {
              handleCvrChange({ target: { value: data.vat_number } });
            } else {
              console.log(
                "Company owning the demo token not found or an error occurred."
              );
              searchParams.delete("demo-token");
              setSearchParams(searchParams); // Update the URL with the modified search params
            }

            if (response.ok && data.bank_vendor_id) {
              setCreateCompanyData((prevData) => ({
                ...prevData,
                bank_vendor_id: data.bank_vendor_id,
              }));
            }
          } catch (error) {
            if (i === retries - 1) {
              throw error;
            }
            await new Promise((resolve) =>
              setTimeout(resolve, delay * Math.pow(2, i))
            );
          }
        }
      } catch (error) {}
    };

    if (demoToken) {
      setDemoInfo();
    }
  }, [demoToken, handleCvrChange, searchParams, setSearchParams]);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box component="form" onSubmit={handleNext} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="fullname"
                label="Navn"
                name="fullname"
                value={createUserData.user_fullname}
                onChange={(e) => {
                  const value = e.target.value;
                  setCreateUserData({
                    ...createUserData,
                    user_fullname: value,
                  });
                  setCreateUserDataValidErrors({
                    ...createUserDataValidErrors,
                    fullname: validateFullname(value),
                  });
                }}
                error={!!createUserDataValidErrors.fullname}
                helperText={createUserDataValidErrors.fullname}
                disabled={loading}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email adresse"
                name="email"
                type="email"
                value={createUserData.user_email}
                onChange={async (e) => {
                  const value = e.target.value;
                  setCreateUserData({ ...createUserData, user_email: value });
                  setCreateUserDataValidErrors({
                    ...createUserDataValidErrors,
                    email: await validateEmail(value),
                  });
                }}
                error={!!createUserDataValidErrors.email}
                helperText={createUserDataValidErrors.email}
                disabled={loading}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Adgangskode"
                name="password"
                type={showPassword ? "text" : "password"}
                value={createUserData.user_password}
                onChange={(e) => {
                  const value = e.target.value;
                  setCreateUserData({
                    ...createUserData,
                    user_password: value,
                  });
                  setCreateUserDataValidErrors({
                    ...createUserDataValidErrors,
                    password: validatePassword(value),
                    passwordConfirm:
                      createUserData.user_password_confirm.length > 0
                        ? validatePasswordConfirm(
                            value,
                            createUserData.user_password_confirm
                          )
                        : "",
                  });
                }}
                error={!!createUserDataValidErrors.password}
                helperText={createUserDataValidErrors.password}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="user_password_confirm"
                label="Bekræft adgangskode"
                name="user_password_confirm"
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => {
                  const value = e.target.value;
                  setCreateUserData({
                    ...createUserData,
                    user_password_confirm: value,
                  });
                  setCreateUserDataValidErrors({
                    ...createUserDataValidErrors,
                    passwordConfirm: validatePasswordConfirm(
                      createUserData.user_password,
                      value
                    ),
                  });
                }}
                value={createUserData.user_password_confirm}
                error={!!createUserDataValidErrors.passwordConfirm}
                helperText={createUserDataValidErrors.passwordConfirm}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 1, mb: 2 }}
                disabled={
                  loading ||
                  Object.values(createUserData).some((value) => !value) ||
                  Object.values(createUserDataValidErrors).some(
                    (error) => error
                  )
                }
              >
                {loading ? <CircularProgress size={24} /> : "Fortsæt"}
              </Button>
              {error && (
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Typography variant="body2" color="textSecondary">
              Har du allerede en bruger?{" "}
              <Button color="primary" onClick={() => navigate("/logind")}>
                Log ind
              </Button>
            </Typography>
          </>
        );
      case 1:
        return (
          <Box component="form" onSubmit={handleNext} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyVatNumber"
              label="CVR nummer"
              name="companyVatNumber"
              autoFocus
              disabled={!!demoToken}
              value={createCompanyData.vat_number}
              onChange={handleCvrChange}
              error={!!cvrError}
              helperText={cvrError}
            />
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 1, mb: 2 }}
              disabled={!cvrValid}
            >
              Næste
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box
            component="form"
            onSubmit={(event) => {
              !token ? handleSubmit(event) : handleSubmitCompany(event);
            }}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Virksomhedsnavn"
              name="companyName"
              value={createCompanyData.name}
              disabled
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="companyAddress"
              label="Adresse"
              name="companyAddress"
              value={`${createCompanyData.address}, ${createCompanyData.zip} ${createCompanyData.city}`}
              disabled
            />

            <FormControl fullWidth margin="normal">
              <Autocomplete
                disablePortal
                disabled={!!demoToken}
                options={banks}
                value={
                  banks.find(
                    (bank) => bank.id === createCompanyData.bank_vendor_id
                  ) || null
                }
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                onChange={(e, newValue) => {
                  setCreateCompanyData({
                    ...createCompanyData,
                    bank_vendor_id: newValue?.id ?? "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Bank" required />
                )}
              />
            </FormControl>

            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 1, mb: 2 }}
            >
              {!demoToken ? "Opret virksomhed" : "Opret demo"}
            </Button>
          </Box>
        );
      default:
        return "Der opstod en fejl";
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography component="h1" variant="h5" mb={3} mt={2}>
              Opret konto
            </Typography>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex", // Enable flexbox
                justifyContent: "flex-end", // Push content to the right
                width: "100%", // Ensure the box spans the full width
                gap: 2,
              }}
            >
              {token && activeStep === 1 ? (
                <Button
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Tilbage
                </Button>
              ) : null}
              {activeStep > 0 && !token ? (
                <Typography variant="body2" color="textSecondary">
                  <Button color="primary" onClick={handlePrevious}>
                    Tilbage
                  </Button>
                </Typography>
              ) : token && activeStep > 1 ? (
                <Typography variant="body2" color="textSecondary">
                  <Button color="primary" onClick={handlePrevious}>
                    Tilbage
                  </Button>
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateCompany;

import { Question, FormValues, Block } from "../../types/commonTypes";
import { Box } from "@mui/material";
import QuestionField from "./QuestionField";
import { geFlexSettingValue } from "../../utils/dataUtils";

import React, { useRef, useEffect } from "react";

interface BlockProps {
  block: Block;
  questions: Question[];
  formValues: FormValues;
  setQuestionAnswer: (questionId: number, value: any) => void;
  setUnitAnswer: (questionId: number, unitId: number) => void;
  handleAutoFill: (question: Question) => void;
  isFieldAutoFilling: Record<string, boolean>;
}

const BlockView: React.FC<BlockProps> = ({
  block,
  questions,
  formValues,
  setQuestionAnswer,
  setUnitAnswer,
  handleAutoFill,
  isFieldAutoFilling,
}) => {
  const questionFieldRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  useEffect(() => {
    questionFieldRefs.current = questions.map(
      (_, index) => questionFieldRefs.current[index] || React.createRef()
    );
  }, [questions]);

  const shrinkFactor =
    geFlexSettingValue(block.settings, "row_view_shrink_factor", "float") ||
    0.3;

  if (block.view === "row") {
    return (
      <Box
        sx={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "row",
          scrollbarWidth: "none",
          paddingTop: 0.6,
          gap: 2,
          paddingRight: 0.1,
          "& > *": {
            flexShrink: shrinkFactor / questions.length,
          },
        }}
      >
        {questions.map((question, index) => {
          let questionValue;
          if (
            (question.format === "text" ||
              question.format === "single-line text") &&
            formValues[`value_${question.id}`].value === undefined
          ) {
            questionValue = question.template_text;
          } else {
            questionValue = formValues[`value_${question.id}`].value ?? "";
          }

          const units = question.units;
          let questionUnit = units.find(
            (u) => u.id === Number(formValues[`unit_${question.id}`].value)
          );
          if (questionUnit === undefined) {
            questionUnit = question.default_unit;
          }

          return (
            <QuestionField
              ref={questionFieldRefs.current[index]}
              question={question}
              formValue={questionValue}
              questionUnit={questionUnit}
              isAutoFilling={isFieldAutoFilling[question.id] || false}
              setQuestionAnswer={setQuestionAnswer}
              setUnitAnswer={setUnitAnswer}
              handleAutoFill={handleAutoFill}
              scrollIntoView={true}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#f8d7da",
        color: "#721c24",
        border: "1px solid #f5c6cb",
        borderRadius: 1,
      }}
    >
      Block with view = <strong>{block.view}</strong> is not implemented yet.
    </Box>
  );
};

export default BlockView;

// src/pages/StartPage.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button, Typography, Box, Grid } from "@mui/material";
import logo from "../logo-white.jpg";

import { useAuth } from "../hooks/useAuth";

const StartPage = () => {
  const { isAuthenticated, authenticationIsLoading } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationIsLoading) {
      return;
    }

    if (!isAuthenticated) {
      return;
    }

    navigate("/dashboard");
  }, [authenticationIsLoading, isAuthenticated, navigate]);

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography component="h1" variant="h5" mt={2}>
              DitESG portalen
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
              Velkommen til DitESG portalen.
              <br />
              Log ind eller opret konto for at påbegynde ESG-rapportering.
            </Typography>

            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate("/logind")}
              >
                Log ind
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate("/opret")}
              >
                Opret konto
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StartPage;

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { IconButton } from "@mui/material";
import { Question } from "../../types/commonTypes";

interface AutoFillButtonProps {
  question: Question;
  handleAutoFill: (question: Question) => void;
  disabled: boolean;
}

const AutoFillButton: React.FC<AutoFillButtonProps> = ({
  question,
  handleAutoFill,
  disabled,
}) => {
  if (!question.is_auto_fillable) return null;
  return (
    <IconButton onClick={() => handleAutoFill(question)} disabled={disabled}>
      <CloudDownloadOutlinedIcon color={disabled ? "disabled" : "secondary"} />
    </IconButton>
  );
};

export default AutoFillButton;

// src/pages/Dashboard.js
import { ReactNode } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Avatar,
  useTheme,
  Theme,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate, NavigateFunction } from "react-router-dom";

interface ESGComponentCardProps {
  height?: number | string;
  avatarColor?: string;
  avatarLetter?: string;
  componentLabel?: string;
  lastUpdated?: string;
  componentData?: ReactNode;
}

const ESGComponentCard = (userProps: ESGComponentCardProps): JSX.Element => {
  const theme: Theme = useTheme();
  const navigate: NavigateFunction = useNavigate();

  const defaultProps: ESGComponentCardProps = {
    height: "0px",
    avatarColor: theme.palette.primary.main,
    avatarLetter: "-",
    componentLabel: "-",
    lastUpdated: "Aldrig",
  };

  const props: ESGComponentCardProps = { ...defaultProps, ...userProps };

  const componentDataDefined = props.componentData !== undefined;
  const newComponentEntry =
    props.lastUpdated === undefined ||
    props.lastUpdated === defaultProps.lastUpdated;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 4,
        height: props.height,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Avatar
            sx={{
              bgcolor: props.avatarColor,
              mr: 2,
            }}
            variant="rounded"
          >
            {props.avatarLetter}
          </Avatar>
          <Typography variant="h5" component="h2" color="primary">
            {props.componentLabel}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">
            {"Senest opdateret: "}
            {props.lastUpdated}
          </Typography>
        </Box>
      </Box>
      {componentDataDefined && (
        <Button
          variant={newComponentEntry ? "contained" : "outlined"}
          color={newComponentEntry ? "primary" : "secondary"}
          onClick={() =>
            navigate("/form", { state: { context: props.componentData } })
          }
        >
          {newComponentEntry ? "Start" : "Genåben"}
        </Button>
      )}
    </Paper>
  );
};

export default ESGComponentCard;

// src/hooks/useAuth.js

import { useState, useEffect } from "react";
import { useAuthContext } from "../contexts/AuthContext";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const context = useAuthContext();

  if (!context) {
    throw new Error("useAuth must be used within a AuthContextProvider");
  }

  const { token, authIsLoading } = context;

  useEffect(() => {
    // let isMounted = true;

    // const verifyToken = async () => {
    //   if (!token) {
    //     setIsAuthenticated(false);
    //     setIsLoading(false);
    //     return;
    //   }

    //   try {
    //     const response = await fetch(
    //       `${process.env.REACT_APP_API_URL}/verify-token`,
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );

    //     if (!isMounted) return;

    //     const isSuccesfull = response.ok;
    //     setIsAuthenticated(isSuccesfull);
    //     if (!isSuccesfull) logout();
    //   } catch (error) {
    //     if (!isMounted) return;

    //     setIsAuthenticated(false);
    //     logout();
    //   } finally {
    //     if (isMounted) setIsLoading(false);
    //   }
    // };

    if (!token) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
    setIsLoading(false);

    // verifyToken();

    // return () => {
    //   isMounted = false;
    // };
    return;
  }, [token]);

  return {
    isAuthenticated,
    authenticationIsLoading: isLoading || authIsLoading,
  };
};

// src/components/ProtectedRoute.js
import React from "react";
// import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { CircularProgress } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const { authenticationIsLoading } = useAuth();

  if (authenticationIsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // if (!isAuthenticated) {
  //   return <Navigate to="/logind" />;
  // }

  return children;
};

export default ProtectedRoute;

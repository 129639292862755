import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const AddCompanyButton: React.FC = () => {
  const navigate = useNavigate();
  const handleAddCompany = () => {
    navigate("/opret");
  };

  return (
    <Button
      onClick={handleAddCompany}
      size="large"
      variant="contained"
      sx={{
        backgroundColor: "white", // Set background to white
        color: "primary.main", // Set text color to primary
        textTransform: "none", // Disable text capitalization
        width: "50vw",
        padding: "1.5rem", // Increase padding for a larger button
        fontSize: "1.1rem", // Increase font size
        "&:hover": {
          backgroundColor: "primary.light", // Light background on hover
          color: "white",
        },
      }}
      startIcon={<AddIcon />} // Add icon before the text
    >
      Opret virksomhed
    </Button>
  );
};

export default AddCompanyButton;

const verifyCompanyAccess = async (token, activeCompanyId) => {
  if (!token || !activeCompanyId) {
    throw new Error("Token and activeCompanyId are required");
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/me/company/verify-access?company_id=${activeCompanyId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return {
      hasAccess: Boolean(data.hasAccess),
      hasDemoAccess: Boolean(data.hasDemoAccess),
    };
  } catch (err) {
    throw err;
  }
};

export default verifyCompanyAccess;

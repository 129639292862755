import { Button, CircularProgress, Tooltip } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { Company } from "../contexts/CompanyContext";

interface ComponentLog {
  is_completed: boolean;
}

interface Component {
  name: string;
  company_component_logs?: ComponentLog[];
}

interface ReportButtonProps {
  loading: boolean;
  hasAccess: boolean;
  hasDemoAccess: boolean;
  companyData: Company;
  componentsData: Component[];
}

const ReportButton = (props: ReportButtonProps) => {
  const navigate: NavigateFunction = useNavigate();

  // Find the "E" component
  const environmentalComponent = props.componentsData.find((comp) => {
    const [prefix] = comp.name.split(" - ");
    return prefix === "E";
  });

  // Retrieve the first log
  const companyComponentLog: ComponentLog | undefined =
    environmentalComponent?.company_component_logs?.[0];

  const dataAvailable: boolean =
    !props.loading &&
    environmentalComponent !== undefined &&
    companyComponentLog !== undefined;

  const reportAvailable: boolean =
    dataAvailable && Boolean(companyComponentLog?.is_completed);

  const isDemoAccount = props.companyData?.isDemo || false;
  const hasLicense: boolean = props.hasAccess;

  if (props.loading) {
    return (
      <Button size="large" variant="contained" color="primary" disabled>
        <CircularProgress size={24} sx={{ mr: 1 }} color="inherit" />
        Indlæser...
      </Button>
    );
  }

  if (!dataAvailable) {
    return (
      <Tooltip
        title="Ingen data tilgængelig. Kontakt support hvis fejl fortsætter."
        placement="top"
      >
        <span>
          <Button size="large" variant="contained" color="primary" disabled>
            <ContentPasteIcon sx={{ mr: 1 }} />
            Vis rapport
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (!reportAvailable) {
    return (
      <Tooltip
        title="Udfør Environmental opgave for at se din rapport"
        placement="top"
      >
        <span>
          <Button size="large" variant="contained" color="primary" disabled>
            <ContentPasteIcon sx={{ mr: 1 }} />
            Vis rapport
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (isDemoAccount && !props.hasDemoAccess) {
    return (
      <Button size="large" variant="contained" color="primary" disabled>
        Overkonto mangler licens
      </Button>
    );
  }

  if (!hasLicense && !isDemoAccount) {
    return (
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => navigate("/koeb-licens")}
      >
        <ContentPasteIcon sx={{ mr: 1 }} />
        Køb og Vis rapport
      </Button>
    );
  }

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      onClick={() => navigate("/rapport")}
    >
      <ContentPasteIcon sx={{ mr: 1 }} />
      Vis rapport
    </Button>
  );
};

export default ReportButton;

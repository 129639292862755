import React from "react";
import { NumericFormat } from "react-number-format";

export const NumericPercentageFormat = React.forwardRef(
  function NumericFormatCustom(props: any, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowLeadingZeros={false}
        valueIsNumericString
        decimalScale={1}
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue ? floatValue <= 100 : true;
        }}
      />
    );
  }
);

export const NumericNumberFormat = React.forwardRef(
  function NumericFormatCustom(props: any, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowLeadingZeros={false}
        valueIsNumericString
        decimalScale={1}
      />
    );
  }
);

export const IntegerNumberFormat = React.forwardRef(
  function NumericFormatCustom(props: any, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowLeadingZeros={false}
        valueIsNumericString
        decimalScale={0}
      />
    );
  }
);

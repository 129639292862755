import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { Unit, Question } from "../../types/commonTypes";
import {
  NumericPercentageFormat,
  NumericNumberFormat,
  IntegerNumberFormat,
} from "./fieldFormatters";
import AutoFillButton from "./AutofillButton";
import { replaceWithSubSuperscripts } from "../../utils/textUtils";
import { geFlexSettingValue } from "../../utils/dataUtils";

interface QuestionFieldProps {
  question: Question;
  formValue: string | number | boolean;
  questionUnit: Unit | undefined;
  isAutoFilling: boolean;
  setQuestionAnswer: (questionId: number, value: any) => void;
  setUnitAnswer: (questionId: number, unitId: number) => void;
  handleAutoFill: (question: Question) => void;
  onClick?: () => void;
  scrollIntoView?: boolean;
}

interface QuestionGridWrapperProps {
  children: React.ReactNode;
  onClick?: () => void;
  scrollIntoView?: boolean;
  refProp?: React.Ref<HTMLDivElement>;
}

const QuestionGridWrapper: React.FC<QuestionGridWrapperProps> = ({
  children,
  onClick,
  scrollIntoView,
  refProp,
}) => {
  const handleClick = () => {
    onClick?.();

    if (
      scrollIntoView &&
      refProp &&
      typeof refProp !== "function" &&
      refProp.current
    ) {
      refProp.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: 3 }}
      ref={refProp}
      onClick={handleClick}
    >
      {children}
    </Grid>
  );
};

const BooleanQuestion = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const { question, formValue, questionUnit, setQuestionAnswer } = props;
    const inputName = `value_${question.id}`;
    const unitInputName = `unit_${question.id}`;

    return (
      <QuestionGridWrapper
        refProp={ref}
        onClick={props.onClick}
        scrollIntoView={props.scrollIntoView}
      >
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id={`label_${question.id}`}>
              {question.label}
            </InputLabel>
            <Select
              labelId={`label_${question.id}`}
              label={question.label}
              value={formValue}
              name={inputName}
              onChange={(e) => setQuestionAnswer(question.id, e.target.value)}
            >
              <MenuItem value={1}>Ja</MenuItem>
              <MenuItem value={0}>Nej</MenuItem>
            </Select>
          </FormControl>
          <input type="hidden" name={unitInputName} value={questionUnit?.id} />
        </Grid>
      </QuestionGridWrapper>
    );
  }
);

const PercentQuestion = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const {
      question,
      formValue,
      questionUnit,
      isAutoFilling,
      setQuestionAnswer,
      handleAutoFill,
    } = props;

    return (
      <QuestionGridWrapper
        refProp={ref}
        onClick={props.onClick}
        scrollIntoView={props.scrollIntoView}
      >
        <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
          <TextField
            type="standard"
            label={question.label}
            variant="outlined"
            fullWidth
            value={formValue}
            name={`value_${question.id}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputComponent: NumericPercentageFormat,
              inputProps: { min: 0, max: 100 },
            }}
            onChange={(e) => {
              setQuestionAnswer(question.id, e.target.value);
            }}
          />
        </Grid>
        {question.is_auto_fillable ? (
          <Grid item xs={0.5}>
            <AutoFillButton
              question={question}
              handleAutoFill={handleAutoFill}
              disabled={isAutoFilling}
            />
          </Grid>
        ) : null}
        <input
          type="hidden"
          name={`unit_${question.id}`}
          value={questionUnit?.id}
        />
      </QuestionGridWrapper>
    );
  }
);

const IntegerQuestion = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const {
      question,
      formValue,
      questionUnit,
      isAutoFilling,
      setQuestionAnswer,
      handleAutoFill,
    } = props;
    return (
      <QuestionGridWrapper
        refProp={ref}
        onClick={props.onClick}
        scrollIntoView={props.scrollIntoView}
      >
        <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
          <TextField
            type="standard"
            label={question.label}
            variant="outlined"
            fullWidth
            value={formValue}
            name={`value_${question.id}`}
            disabled={isAutoFilling}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {replaceWithSubSuperscripts(questionUnit?.label)}
                </InputAdornment>
              ),
              inputComponent: IntegerNumberFormat,
            }}
            onChange={(e) => {
              setQuestionAnswer(question.id, e.target.value);
            }}
          />
        </Grid>
        {question.is_auto_fillable ? (
          <Grid item xs={0.5}>
            <AutoFillButton
              question={question}
              handleAutoFill={handleAutoFill}
              disabled={isAutoFilling}
            />
          </Grid>
        ) : null}
        <input
          type="hidden"
          name={`unit_${question.id}`}
          value={questionUnit?.id}
        />
      </QuestionGridWrapper>
    );
  }
);

const TextQuestion = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const {
      question,
      formValue,
      questionUnit,
      isAutoFilling,
      setQuestionAnswer,
      handleAutoFill,
    } = props;
    const formatTextWithLineBreaks = (text: string) => {
      // Guard against non-string values
      if (!text || typeof text !== "string") {
        return "";
      }

      return text?.replace(/\\n/g, "\n") || "";
    };

    if (typeof formValue !== "string") {
      return <>Expected a string in TextQuestion</>;
    }
    return (
      <QuestionGridWrapper
        refProp={ref}
        onClick={props.onClick}
        scrollIntoView={props.scrollIntoView}
      >
        <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
          <TextField
            type="standard"
            placeholder={question?.placeholder || ""}
            label={question.label}
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            maxRows={12}
            value={formatTextWithLineBreaks(formValue)}
            name={`value_${question.id}`}
            inputProps={{
              maxLength: question?.max_characters || 800, // Adjust this number as needed
            }}
            helperText={`${formValue?.length || 0}/${
              question?.max_characters || 800
            }`} // Optional character counter
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
                "& textarea": {
                  padding: "16px",
                },
                whiteSpace: "pre-wrap",
              },
            }}
            disabled={isAutoFilling || false}
            onChange={(e) => {
              setQuestionAnswer(question.id, e.target.value);
            }}
          />
        </Grid>
        {question.is_auto_fillable ? (
          <Grid item xs={0.5}>
            <AutoFillButton
              question={question}
              handleAutoFill={handleAutoFill}
              disabled={isAutoFilling}
            />
          </Grid>
        ) : null}
        <input
          type="hidden"
          name={`unit_${question.id}`}
          value={questionUnit?.id}
        />
      </QuestionGridWrapper>
    );
  }
);

const SingleLineText = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const {
      question,
      formValue,
      questionUnit,
      isAutoFilling,
      setQuestionAnswer,
      handleAutoFill,
    } = props;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value || "";

      // Check if a regexp exists
      if (question.regexp_validation) {
        const regexp = new RegExp(question.regexp_validation);

        if (!regexp.test(inputValue)) {
          return;
        }
      }

      // If valid, set the value
      setQuestionAnswer(question.id, inputValue);
    };

    return (
      <QuestionGridWrapper
        refProp={ref}
        onClick={props.onClick}
        scrollIntoView={props.scrollIntoView}
      >
        <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
          <TextField
            type="text" // Change type to text for single-line input
            label={question.label}
            variant="outlined"
            placeholder={question?.placeholder || ""}
            fullWidth
            value={formValue}
            name={`value_${question.id}`}
            inputProps={{
              maxLength: 200, // Adjust as needed
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",
                "& textarea": {
                  padding: "16px",
                },
                whiteSpace: "pre-wrap",
              },
            }}
            disabled={isAutoFilling}
            onChange={onChange}
          />
        </Grid>
        {question.is_auto_fillable ? (
          <Grid item xs={0.5}>
            <AutoFillButton
              question={question}
              handleAutoFill={handleAutoFill}
              disabled={isAutoFilling}
            />
          </Grid>
        ) : null}
        <input
          type="hidden"
          name={`unit_${question.id}`}
          value={questionUnit?.id}
        />
      </QuestionGridWrapper>
    );
  }
);

const FloatQuestion = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const {
      question,
      formValue,
      questionUnit,
      isAutoFilling,
      setQuestionAnswer,
      setUnitAnswer,
      handleAutoFill,
    } = props;
    const units = question.units;

    const [open, setOpen] = React.useState(false);

    if (units.length === 1) {
      return (
        <QuestionGridWrapper
          refProp={ref}
          onClick={props.onClick}
          scrollIntoView={props.scrollIntoView}
        >
          <Grid item xs={question.is_auto_fillable ? 11.5 : 12}>
            <TextField
              type="standard"
              label={question.label}
              variant="outlined"
              fullWidth
              value={formValue}
              name={`value_${question.id}`}
              disabled={isAutoFilling}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {replaceWithSubSuperscripts(questionUnit?.label)}
                  </InputAdornment>
                ),
                inputComponent: NumericNumberFormat,
                inputProps: { min: 0, max: 100 },
              }}
              onChange={(e) => {
                setQuestionAnswer(question.id, e.target.value);
              }}
            />
          </Grid>
          {question.is_auto_fillable ? (
            <Grid item xs={0.5}>
              <AutoFillButton
                question={question}
                handleAutoFill={handleAutoFill}
                disabled={isAutoFilling}
              />
            </Grid>
          ) : null}
          <input
            type="hidden"
            name={`unit_${question.id}`}
            value={questionUnit?.id}
          />
        </QuestionGridWrapper>
      );
    } else {
      const rawFieldProportion =
        geFlexSettingValue(question.settings, "field_proportion", "float") ??
        3 / 4;

      const FieldProportion = Math.max(0, Math.min(1, rawFieldProportion));

      const ValueNumberGridColumns = question.is_auto_fillable
        ? FieldProportion * 12 - 0.5
        : FieldProportion * 12;
      const UnitNumberGridColumns = 12 - ValueNumberGridColumns;

      return (
        <QuestionGridWrapper
          refProp={ref}
          onClick={props.onClick}
          scrollIntoView={props.scrollIntoView}
        >
          <Grid item xs={ValueNumberGridColumns}>
            <TextField
              type="standard"
              label={question.label}
              variant="outlined"
              fullWidth
              value={formValue}
              name={`value_${question.id}`}
              InputProps={{
                inputComponent: NumericNumberFormat,
                inputProps: { min: 0, max: 100 },
              }}
              onChange={(e) => {
                setQuestionAnswer(question.id, e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={UnitNumberGridColumns}
            id={`label_unit_${question.id}`}
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel>Vælg enhed</InputLabel>
              <Select
                open={open}
                onClose={() => setOpen(false)}
                onOpen={(e) => {
                  e.stopPropagation(); // prevent bubbling

                  if (ref && typeof ref !== "function" && ref.current) {
                    if (!open) {
                      function waitForStablePosition(
                        el: HTMLElement,
                        beforeRect: DOMRect,
                        onStable: () => void,
                        maxAttempts = 20,
                        attempt = 0
                      ) {
                        setTimeout(() => {
                          const afterRect = el.getBoundingClientRect();

                          const moved =
                            beforeRect.x !== afterRect.x ||
                            beforeRect.y !== afterRect.y;

                          if (!moved || attempt >= maxAttempts) {
                            onStable(); // Stable or timed out
                          } else {
                            // Keep waiting
                            waitForStablePosition(
                              el,
                              afterRect,
                              onStable,
                              maxAttempts,
                              attempt + 1
                            );
                          }
                        }, 50);
                      }

                      const el = ref.current;

                      const beforeRect = el.getBoundingClientRect();

                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "center",
                      });

                      waitForStablePosition(el, beforeRect, () => {
                        setOpen(true);
                      });
                    } else {
                      setOpen(false);
                    }
                  } else {
                    setOpen(!open);
                  }
                }}
                id={`select_unit_${question.id}`}
                labelId={`label_unit_${question.id}`}
                label="Vælg enhed"
                variant="outlined"
                value={questionUnit?.id}
                name={`unit_${question.id}`}
                onChange={(e: SelectChangeEvent<number>) => {
                  if (typeof e.target.value !== "number") {
                    throw new Error("UnitId is not of type number");
                  }
                  setUnitAnswer(question.id, e.target.value);
                }}
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {replaceWithSubSuperscripts(unit.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {question.is_auto_fillable ? (
            <Grid item xs={0.5}>
              <AutoFillButton
                question={question}
                handleAutoFill={handleAutoFill}
                disabled={isAutoFilling}
              />
            </Grid>
          ) : null}
        </QuestionGridWrapper>
      );
    }
  }
);

const QuestionField = React.forwardRef<HTMLDivElement, QuestionFieldProps>(
  (props, ref) => {
    const { question } = props;

    const renderQuestionComponent = () => {
      switch (question.format) {
        case "bool":
          return <BooleanQuestion {...props} ref={ref} />;
        case "percent":
          return <PercentQuestion {...props} ref={ref} />;
        case "integer":
          return <IntegerQuestion {...props} ref={ref} />;
        case "text":
          return <TextQuestion {...props} ref={ref} />;
        case "single-line text":
          return <SingleLineText {...props} ref={ref} />;
        default:
          return <FloatQuestion {...props} ref={ref} />;
      }
    };

    return renderQuestionComponent();
  }
);

export default QuestionField;

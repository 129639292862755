// src/pages/Dashboard.js
import { Paper, Skeleton } from "@mui/material";

interface ESGComponentCardSkeletonProps {
  height?: number | string;
}

const ESGComponentCardSkeleton = (
  props: ESGComponentCardSkeletonProps
): JSX.Element => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 4,
        height: props.height,
      }}
    >
      <Skeleton variant="text" width={100} />
      <Skeleton variant="rectangular" width={80} height={36} />
    </Paper>
  );
};

export default ESGComponentCardSkeleton;

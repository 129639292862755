import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Divider,
  Container,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FormSection from "../components/FormSection";
import TipSection from "../components/TipSection";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "../contexts/SnackbarContext";
import AreaHeader from "../components/formSection/AreaHeader";

import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";

//TODO Type on formvalues, area setting on formvalues

// Define retryFetch outside the component
const retryFetch = async (fetchFn, maxRetries = 3, initialDelay = 1000) => {
  let retries = 0;
  while (retries < maxRetries) {
    try {
      return await fetchFn();
    } catch (error) {
      retries++;
      if (retries === maxRetries) throw error;
      const delay = initialDelay * Math.pow(2, retries - 1);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

const FormPage = ({ isDataModified, setIsDataModified }) => {
  const { token } = useAuthContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [isFormCompleted, setFormCompleted] = useState(undefined);
  const [activeStep, setActiveStep] = useState(-1);
  const [steps, setSteps] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const component = location.state.context;
  const [componentStatusImagePaths, setComponentStatusImagePaths] = useState(
    []
  );
  const [questions, setQuestions] = useState([]);
  const [formValues, setFormValues] = useState({
    // Example structure:
    // key1: {
    //   type: 'text',
    //   active: false,
    //   value: ''
    // },
    // key2: {
    //   type: 'checkbox',
    //   active: true,
    //   value: 'checked'
    // }
  });
  const { activeAccountingYearId, companyData, activeCompanyId } =
    useCompanyContext();
  const [loading, setLoading] = useState(false);
  const [isFieldAutoFilling, setIsFieldAutoFilling] = useState({});
  const [, setAutoFillAttempts] = useState({});
  const { showSnackbar } = useSnackbar();

  const accountingYear = companyData?.accountingYears.find(
    (accountingYear) => accountingYear.id === activeAccountingYearId
  );

  const yearOnly = accountingYear?.start_date?.substring(0, 4);

  // Fetch component status image paths
  useEffect(() => {
    const fetchComponentData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/me/company/components?accounting_year_id=" +
            activeAccountingYearId +
            "&company_id=" +
            activeCompanyId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching component data");
        throw error; // Fixed error throwing
      }
    };

    const processComponentData = (data) => {
      return data.map((component) => ({
        id: component.id,
        incomplete_status_image_path: component.incomplete_status_image_path,
        completed_status_image_path: component.completed_status_image_path,
        is_completed: component.company_component_logs[0]?.is_completed,
      }));
    };

    const loadData = async () => {
      setLoading(true);
      try {
        const data = await retryFetch(fetchComponentData, 3, 200);
        const processedData = processComponentData(data);
        setComponentStatusImagePaths(processedData);
      } catch (error) {
        console.error("Failed after retries:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!token || !activeAccountingYearId || !activeCompanyId) return;
    loadData();
  }, [token, activeAccountingYearId, activeCompanyId]);

  /**
   * Company Area Settings
   */

  const [companyAreaSettings, setCompanyAreaSettings] = useState([]);
  const [activeCompanyAreaSettingIndex, setActiveCompanyAreaSettingIndex] =
    useState(-1);
  const [areaEnabled, setAreaEnabled] = useState(null);

  const setQuestionAnswer = useCallback(
    (questionId, value, isModification = true) => {
      setIsDataModified(isModification);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [`value_${questionId}`]: {
          ...prevFormValues[`value_${questionId}`], // Preserve existing properties
          value: value,
        },
      }));
    },
    [setIsDataModified]
  );

  const setUnitAnswer = (unitId, value, isModification = true) => {
    setIsDataModified(isModification);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [`unit_${unitId}`]: { ...prevFormValues[`unit_${unitId}`], value: value },
    }));
  };

  const handleAutoFill = useCallback(
    async (question, defaultValue = undefined) => {
      try {
        setIsFieldAutoFilling((prevIsFieldAutoFilling) => ({
          ...prevIsFieldAutoFilling,
          [question.id]: true,
        }));

        const companyVatNumber = companyData?.vatNumber || 0;
        const companyName = companyData?.name || "";

        //Validate vat number, all danish vat numbers are 8 numeric digits
        if (
          !companyVatNumber ||
          companyVatNumber.length !== 8 ||
          isNaN(companyVatNumber)
        ) {
          showSnackbar(
            "Kunne ikke få fat i gyldig CVR nummber. Kontakt venligst support.",
            "error"
          );
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/autofill-endpoint/${question.autofill_endpoint_id}?cvr=${companyVatNumber}&accounting_year_id=${activeAccountingYearId}`,
          {
            headers: {
              "Content-Type": "application/json", // Fixed content type
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (!data?.data?.autofillInput) {
          showSnackbar(
            `Fandt ingen ${question.label} data for ${companyName} fra Erhvervsstyrelsen`,
            "warning"
          );
          if (defaultValue !== undefined) {
            setQuestionAnswer(question.id, defaultValue);
          }
        } else {
          setQuestionAnswer(question.id, data?.data?.autofillInput);
        }
      } catch (error) {
        showSnackbar(
          "Hov... Der opstod en fejl. Kontakt venligst supporten.",
          "error"
        );
        console.error("Error fetching data:", error);
      } finally {
        setIsFieldAutoFilling((prevIsFieldAutoFilling) => ({
          ...prevIsFieldAutoFilling,
          [question.id]: false,
        }));
      }
    },
    [
      companyData,
      showSnackbar,
      setQuestionAnswer,
      activeAccountingYearId,
      token,
    ]
  );

  // Fetch area data and set active step
  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/areas?component_id=" +
            component.id +
            "&company_id=" +
            activeCompanyId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Failed fetching areas");
        throw error;
      }
    };

    const loadData = async () => {
      try {
        const areasData = await retryFetch(fetchAreas, 3, 200);
        setSteps(areasData);
        if (areasData.length > 0) {
          setActiveStep(0);
        }
      } catch (error) {
        console.error("Failed after retries:", error);
      }
    };

    if (!component.id || !activeCompanyId || !token) return;
    loadData();
  }, [component.id, activeCompanyId, token]);

  // Fetch area settings
  useEffect(() => {
    const fetchAreaSettings = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            "/me/company/area-settings" +
            "?company_id=" +
            activeCompanyId,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        throw error;
      }
    };

    const loadData = async () => {
      try {
        const areaSettingsData = await retryFetch(fetchAreaSettings, 3, 200);
        setCompanyAreaSettings(areaSettingsData);
      } catch (error) {
        console.error("Failed after retries:", error);
      }
    };

    if (!token || !activeCompanyId) return;
    loadData();
  }, [token, activeCompanyId]);

  //Fetch questions and set initial form values
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/questions?component_id=${component.id}&accounting_year_id=${activeAccountingYearId}&company_id=${activeCompanyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        throw error;
      }
    };

    const processQuestionData = (data) => {
      const initialFormValues = {};

      const processNumericAnswer = (question) => {
        const answeredQuestion = question.questions?.[0] ?? "";
        let answeredValue = "";
        let units = question.units;
        let defaultUnit = question.default_unit;

        if (answeredQuestion) {
          answeredValue = parseFloat(answeredQuestion.value);

          if (answeredQuestion.question_answer_values?.[0]) {
            const questionAnswerValue =
              answeredQuestion.question_answer_values[0];
            answeredValue = parseFloat(questionAnswerValue.unit_value);
            defaultUnit =
              units.find((unit) => unit.id === questionAnswerValue.unit_id) ||
              defaultUnit;
          }
        }

        initialFormValues[`value_${question.id}`] = {
          type: "numeric",
          active: true,
          value: answeredValue,
        };
        initialFormValues[`unit_${question.id}`] = {
          type: "id",
          active: true,
          value: defaultUnit.id,
        };
      };

      const processTextAnswer = (question) => {
        const answeredQuestion = question.questions?.[0] ?? "";
        let answeredValue = question.template_text;
        let units = question.units;
        let defaultUnit = question.default_unit;

        if (answeredQuestion) {
          answeredValue = answeredQuestion.text_value;

          if (answeredQuestion.question_answer_values?.[0]) {
            const questionAnswerValue =
              answeredQuestion.question_answer_values[0];
            answeredValue = questionAnswerValue.text_value;
            defaultUnit =
              units.find((unit) => unit.id === questionAnswerValue.unit_id) ||
              defaultUnit;
          }
        }

        initialFormValues[`value_${question.id}`] = {
          type: "text",
          active: true,
          value: answeredValue,
        };
        initialFormValues[`unit_${question.id}`] = {
          type: "id",
          active: true,
          value: defaultUnit.id,
        };
      };

      data.forEach((question) => {
        if (question.format === "text") {
          processTextAnswer(question);
        } else if (question.format === "single-line text") {
          processTextAnswer(question);
        } else {
          processNumericAnswer(question);
        }
      });

      return { questions: data, formValues: initialFormValues };
    };

    const loadQuestions = async () => {
      try {
        const data = await retryFetch(fetchQuestions, 3, 200);
        const { questions, formValues } = processQuestionData(data);
        setQuestions(questions);
        setFormValues(formValues);
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    };

    if (!component.id || !activeAccountingYearId || !token || !activeCompanyId)
      return;
    loadQuestions();
  }, [component.id, activeAccountingYearId, token, activeCompanyId]);

  // Handle auto-fill
  useEffect(() => {
    const handleQuestionAutoFill = () => {
      setAutoFillAttempts((prevAutoFillAttempts) => {
        const newAutoFillAttempts = { ...prevAutoFillAttempts };

        questions.forEach((question) => {
          if (
            (formValues[`value_${question.id}`].value === null ||
              formValues[`value_${question.id}`].value === "") &&
            question.is_auto_fillable &&
            !newAutoFillAttempts[question.id]
          ) {
            handleAutoFill(question, "");
            newAutoFillAttempts[question.id] = true;
          }
        });

        return newAutoFillAttempts;
      });
    };

    if (questions.length > 0) {
      handleQuestionAutoFill();
    }
  }, [questions, formValues, handleAutoFill]);

  // Update active company area setting index based on current step and area settings
  useEffect(() => {
    if (
      !steps?.length ||
      !companyAreaSettings?.length ||
      activeStep === undefined
    ) {
      setActiveCompanyAreaSettingIndex(-1);
      return;
    }

    setActiveCompanyAreaSettingIndex((prevActiveAreaSetting) => {
      const area = steps[activeStep];
      if (!area?.id) return -1;
      return companyAreaSettings.findIndex((e) => e.area_id === area.id);
    });
  }, [steps, activeStep, companyAreaSettings]);

  // Determine if area should be enabled based on settings and display options
  useEffect(() => {
    const determineAreaEnabled = (area, companyAreaSetting) => {
      if (!area?.id) {
        return null;
      }

      // Always enabled if not optional
      if (!area.optional_display) {
        return true;
      }

      // Use default value if no setting exists
      if (!companyAreaSetting) {
        return Boolean(area.optional_display_default_value);
      }

      // Use setting value if exists
      return Boolean(companyAreaSetting.enabled);
    };

    try {
      const area = steps[activeStep];
      const companyAreaSetting =
        companyAreaSettings[activeCompanyAreaSettingIndex];
      const areaEnabled = determineAreaEnabled(area, companyAreaSetting);

      setAreaEnabled(areaEnabled);
    } catch (error) {
      console.error("Error determining area enabled state:", error);
      setAreaEnabled(null);
    }
  }, [steps, activeStep, companyAreaSettings, activeCompanyAreaSettingIndex]);

  useEffect(() => {
    const isAreaEnabled = areaEnabled ?? false;
    const area = steps[activeStep];
    const areaQuestions = questions.filter(
      (question) => question.area_id === area?.id
    );

    setFormValues((prevFormValues) => {
      const updates = areaQuestions.reduce(
        (acc, areaQuestion) => ({
          ...acc,
          [`value_${areaQuestion.id}`]: {
            ...prevFormValues[`value_${areaQuestion.id}`],
            active: isAreaEnabled,
          },
          [`unit_${areaQuestion.id}`]: {
            ...prevFormValues[`unit_${areaQuestion.id}`],
            active: isAreaEnabled,
          },
        }),
        {}
      );

      return {
        ...prevFormValues,
        ...updates,
      };
    });
  }, [areaEnabled, questions, activeStep, steps]);

  // If completed submit form answers and navigate to dashboard
  useEffect(() => {
    if (isFormCompleted === undefined) return;

    const submitFormAnswers = async (isCompleted) => {
      const transformedValues = Object.entries(formValues)
        .filter(([key, item]) => item.active)
        .reduce(
          (acc, [key, item]) => ({
            ...acc,
            [key]: item.value,
          }),
          {}
        );

      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/questions/answer?accounting_year_id=" +
          activeAccountingYearId +
          "&component_id=" +
          component.id +
          "&is_completed=" +
          isCompleted +
          "&company_id=" +
          activeCompanyId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(transformedValues),
        }
      );

      const data = await response.json();

      return data;
    };

    const handleSubmission = async () => {
      setLoading(true);
      try {
        let isCompleted = isFormCompleted;
        await retryFetch(() => submitFormAnswers(isCompleted), 3, 200);
        setIsDataModified(false);
        if (isCompleted) {
          showSnackbar("Dine svar er gemt og godkendt", "success");
          navigate("/dashboard");
        } else {
          showSnackbar("Dine svar er gemt til senere", "info");
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    handleSubmission();
  }, [
    isFormCompleted,
    activeAccountingYearId,
    component.id,
    formValues,
    navigate,
    setIsDataModified,
    showSnackbar,
    token,
    activeCompanyId,
  ]);

  const handleNext = (isCompleted = true) => {
    setLoading(true);
    // Scroll to top of page
    window.scrollTo(0, 0);

    //Handle completed case with empty fields
    if (isCompleted === true && activeStep === steps.length - 1) {
      //Fill empty fields
      if (
        Object.values(formValues).some(
          (object) => object.value === "" && object.active
        )
      ) {
        // Get empty fields.
        const emptyFields = Object.entries(formValues)
          .filter(([_, object]) => object.active && object.value === "")
          .map(([key]) => key);

        // Foreach empty field, set the value to 0 on formValues.
        const newFormValues = emptyFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: {
              ...formValues[field],
              value: formValues[field].type === "text" ? "" : 0,
            },
          }),
          {}
        );

        setFormValues((prevFormValues) => {
          const updatedFormValues = {
            ...prevFormValues,
            ...newFormValues,
          };

          return updatedFormValues;
        });
      }

      setOpenDialog(true);
    } else if (isCompleted === false && activeStep === steps.length - 1) {
      setOpenDialog(false);
      setFormCompleted(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setLoading(false);
    return;
  };

  const handleBack = () => {
    // Gather form data
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenDialog(false);

    setFormCompleted(true);
  };

  const toggleEnableArea = () => {
    setLoading(true);

    const areaId = steps[activeStep].id;
    const activeCompanyAreaSetting =
      companyAreaSettings[activeCompanyAreaSettingIndex];

    // Determine request method and body
    const isUpdate = !!activeCompanyAreaSetting; // Check if activeCompanySetting exists
    const requestMethod = isUpdate ? "PATCH" : "PUT";
    const requestBody = {
      enabled: activeCompanyAreaSetting?.enabled ? false : true,
      ...(isUpdate ? { id: activeCompanyAreaSetting.id } : { area_id: areaId }), // Add id or area_id based on method
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/me/company/area-setting?company_id=` +
        activeCompanyId,
      {
        method: requestMethod,
        headers: {
          "Content-Type": "application/json", // Fixed content type
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Update the area settings
        setCompanyAreaSettings(
          isUpdate
            ? companyAreaSettings.map((companyAreaSetting) =>
                companyAreaSetting.area_id === areaId
                  ? { ...data.areaSetting }
                  : companyAreaSetting
              )
            : [...companyAreaSettings, data.areaSetting]
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ToggleEnableAreaButton = ({ sx, className, variant }) => {
    return (
      <Button
        variant={variant || "contained"}
        color="primary"
        onClick={toggleEnableArea} // Assuming this function is accessible within scope
        disabled={loading} // Assuming loading is defined
        sx={sx || {}} // Apply default empty object if no sx is passed
        className={className || ""} // Apply default empty string if no className is passed
      >
        {!areaEnabled ? "Tilvælg" : "Fravælg"}
      </Button>
    );
  };

  const stepperContainerRef = useRef(null);
  const stepRefs = useRef([]);
  useEffect(() => {
    stepRefs.current = steps.map(
      (_, index) => stepRefs.current[index] || React.createRef()
    );
  }, [steps]);

  /** */
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setIsOverflowing(entry.contentRect.width < entry.target.scrollWidth);
      }
    });

    if (stepperContainerRef.current) {
      observer.observe(stepperContainerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ mb: 4 }}>
        {component.name.split(" - ")[1]}
      </Typography>

      {/* Stepper */}
      <Box
        sx={{
          overflowX: isOverflowing ? "auto" : "hidden",
          mb: 4,
          display: { md: "block" },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            display: "none",
            "&::-webkit-scrollbar": {
              display: isOverflowing ? "block" : "none",
            },
          },
          scrollBehavior: "smooth",
        }}
        ref={stepperContainerRef}
      >
        <Stepper
          activeStep={activeStep}
          sx={{ minWidth: "600px", cursor: "pointer", display: "flex" }}
        >
          {steps.map((step, index) => (
            <Step
              key={step.id}
              completed={activeStep > index}
              onClick={() => {
                setActiveStep(index);
                // Scroll the clicked step into the center
                stepRefs.current[index]?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
              }}
              sx={{
                "&:hover": {
                  "& .MuiStepIcon-root": {
                    color: "primary.main",
                    transform: "scale(1.1)",
                    transition: "all 0.3s ease",
                  },
                },
                p: 0.5,
                cursor: "pointer",
                "& .MuiStepLabel-iconContainer": { cursor: "pointer" },
                "& .MuiStepLabel-labelContainer": { cursor: "pointer" },
              }}
            >
              <StepLabel
                sx={{ whiteSpace: "nowrap" }}
                ref={stepRefs.current[index]}
              >
                {step?.label || step?.name || "Unavngivet fane"}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row layout on large screens
          alignItems: "flex-start",
          width: "100%", // Ensures it takes full container width
          gap: 4, // Adds spacing between sections
        }}
      >
        {/* Form Section (50%) */}
        <Box sx={{ flex: 1, minWidth: 0 }}>
          {areaEnabled ? (
            <FormSection
              questions={questions}
              activeStep={activeStep}
              steps={steps}
              formValues={formValues}
              setQuestionAnswer={setQuestionAnswer}
              setUnitAnswer={setUnitAnswer}
              isDataModified={isDataModified}
              setIsDataModified={setIsDataModified}
              handleAutoFill={handleAutoFill}
              isFieldAutoFilling={isFieldAutoFilling}
            />
          ) : (
            <>
              <AreaHeader
                activeStep={activeStep}
                steps={steps}
                descriptionTxt={
                  steps[activeStep]?.optional_display_description ||
                  `Tilvælg rapportering af virksomhedens '${steps[
                    activeStep
                  ]?.name.toLowerCase()}' hvis relevant, eller fortsæt ved at klikke på 'Næste'.`
                }
              />
              <ToggleEnableAreaButton sx={{ mb: 12 }} />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
            }}
          >
            {/* Toggle Area Button (Only if applicable) */}
            {steps[activeStep]?.optional_display && areaEnabled ? (
              <ToggleEnableAreaButton sx={{ mr: 2 }} variant="contained" />
            ) : null}

            {/* Navigation Buttons */}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", ml: "auto" }}
            >
              <Button
                variant="outlined"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                sx={{ mr: 2 }}
              >
                Tilbage
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext(true)}
                startIcon={
                  activeStep === steps.length - 1 ? <CheckIcon /> : null
                }
                disabled={loading}
              >
                {activeStep === steps.length - 1 ? "Godkend" : "Næste"}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Divider (Visible on Large Screens) */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", md: "block" }, // Hide on mobile
            width: "0.5px",
            backgroundColor: "#88b3af",
            alignSelf: "stretch",
          }}
        />

        {/* Tip Section (50%) */}
        <Box sx={{ flex: 1, minWidth: 0 }}>
          {areaEnabled ? (
            <TipSection steps={steps} activeStep={activeStep} />
          ) : (
            <TipSection
              steps={steps}
              activeStep={activeStep}
              displayTipHeader={false}
              displayTipText={false}
              displayFiles={false}
            />
          )}
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          variant="h5"
          color="primary"
          style={{ padding: "3rem" }}
          id="alert-dialog-title"
          textAlign={"center"}
        >
          Tillykke!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign={"center"}>
            {`${component.celebratory_message} ${yearOnly}.`}
          </DialogContentText>
          <div className="flex justify-center flex-wrap">
            {componentStatusImagePaths
              .sort((a, b) => a.id - b.id)
              .map((componentStatusImagePath) => (
                <Box
                  key={componentStatusImagePath.id}
                  component="img"
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${
                    componentStatusImagePath.is_completed ||
                    component.id === componentStatusImagePath.id
                      ? componentStatusImagePath.completed_status_image_path
                      : componentStatusImagePath.incomplete_status_image_path
                  }`}
                  alt={`Component ${componentStatusImagePath.id} completion status`}
                  className="w-1/4 object-contain m-2" // Tailwind CSS classes for responsive images with margin
                />
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleCloseDialog}
            disabled={loading}
            color="primary"
          >
            Tilbage
          </Button> */}
          <Button
            onClick={handleConfirmDialog}
            color="primary"
            disabled={loading}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FormPage;

/**
 * Multiple accouting years and multiple companies supported
 * Uses saved accountingYearId and companyId.
 *
 * If companyId is not in the users companies id array (userContext), then defaults to company_ids[0]
 * accountingYearId also defaults to company.accounting_years[0]
 */

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useAuthContext, AuthContextType } from "./AuthContext";
import { useUserContext } from "./UserContext";

export interface AccountingYear {
  id: number;
  startDate: Date;
  endDate: Date;
}

export interface Company {
  id: number;
  name: string;
  vatNumber: string;
  bankVendorId: number;
  accountingYears: AccountingYear[];
  // License data
  stripeCustomerId: string;
  hasAccess: Boolean;
  hasDemoAccess: Boolean;
  // Demo data
  demoToken: string;
  isDemo: boolean;
}

export interface CompanyContextType {
  loadingCompanyData: boolean;
  companyData: Company | null;
  setCompanyData: (data: Company | null) => void;
  companyDataError: Error | null;
  activeCompanyId: number | null;
  setActiveCompanyId: (id: number | null) => void;
  activeAccountingYearId: number | null;
  setActiveAccountingYearId: (id: number | null) => void;
}

const getInitialCompanyId = (defaultValue: number | null) => {
  const savedCompanyId = localStorage.getItem("companyId");
  if (savedCompanyId) {
    const parsedId = parseInt(savedCompanyId, 10);
    return parsedId;
  }
  return defaultValue;
};

const getAccountingYearId = () => {
  const savedAccountingYearId = localStorage.getItem("accountingYearId");
  return savedAccountingYearId ? parseInt(savedAccountingYearId, 10) : null;
};

const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { token }: AuthContextType = useAuthContext();

  const [companyData, setCompanyData] = useState<Company | null>(null);
  const [loadingCompanyData, setLoadingCompanyData] = useState<boolean>(false);
  const [companyDataError, setCompanyDataError] = useState<Error | null>(null);

  const [activeAccountingYearId, setActiveAccountingYearIdState] = useState<
    number | null
  >(getAccountingYearId());

  const { userData, userDataLoading } = useUserContext();

  const [activeCompanyId, setActiveCompanyIdState] = useState<number | null>(
    getInitialCompanyId(userData?.companies?.[0]?.company_id ?? null)
  );
  // Should probably not belong to Company context
  const setActiveAccountingYearId = useCallback(
    (newAccountingYearId: number | null) => {
      if (loadingCompanyData) {
        console.warn(
          "You shouldn't modify accountingYearId while loading company data"
        );
        return;
      }

      if (
        typeof newAccountingYearId !== "number" &&
        newAccountingYearId !== null
      ) {
        throw new Error(
          "accountingYearId must be a number or null when using setActiveAccountingYearId"
        );
      }

      const defaultYearId: number | null =
        companyData?.accountingYears?.[0]?.id ?? null;

      const isValidYearId =
        newAccountingYearId !== null &&
        companyData?.accountingYears?.some(
          (year) => year.id === newAccountingYearId
        );

      const finalYearId: number | null = isValidYearId
        ? newAccountingYearId
        : defaultYearId;

      setActiveAccountingYearIdState(finalYearId);

      if (finalYearId !== null) {
        localStorage.setItem("accountingYearId", String(finalYearId));
      } else {
        localStorage.removeItem("accountingYearId");
      }
    },
    [loadingCompanyData, companyData]
  );

  const setActiveCompanyId = useCallback(
    (newCompanyId: number | null) => {
      if (userDataLoading) {
        console.warn(
          "You shouldn't set activeCompanyId while loading userData"
        );
        return;
      }

      if (typeof newCompanyId !== "number" && newCompanyId !== null) {
        throw new Error(
          "companyId must be a number or null when using setActiveCompanyId"
        );
      }

      if (
        userData?.companies?.some((e: any) => e.company_id === newCompanyId)
      ) {
        setActiveCompanyIdState(newCompanyId);
        localStorage.setItem("companyId", String(newCompanyId));
      } else if (userData?.companies?.length > 0) {
        setActiveCompanyIdState(userData.companies[0].company_id);
        localStorage.setItem("companyId", userData.companies[0].company_id);
      } else {
        setActiveCompanyIdState(null);
        localStorage.removeItem("companyId");
      }
    },
    [userData, userDataLoading] // Updated dependency array
  );

  // Sets default company ID
  useEffect(() => {
    const setDefaultCompanyIdIfInvalid = (
      userCompanies: any,
      currentCompanyId: number | null
    ) => {
      const fetchCompanyId: number | null =
        currentCompanyId || userCompanies?.companies?.[0]?.company_id;
      if (fetchCompanyId && fetchCompanyId !== currentCompanyId)
        setActiveCompanyId(fetchCompanyId);
      return fetchCompanyId;
    };

    if (!userDataLoading) {
      const fetchCompanyId = setDefaultCompanyIdIfInvalid(
        userData,
        activeCompanyId
      );

      if (!fetchCompanyId) {
        setCompanyData(null);
      }
    }
  }, [userData, userDataLoading, activeCompanyId, setActiveCompanyId]);

  // Sets default accounting year if its not set
  useEffect(() => {
    const setDefaultAccountingYearIfInvalid = (
      accountingYears: any[] | undefined,
      currentYearId: number | null
    ) => {
      if (!accountingYears?.length) return;

      const isValidYear = accountingYears?.some(
        (year) => year.id === currentYearId
      );
      if (!isValidYear) {
        // If current year is invalid, default to first year or null
        const defaultYearId = accountingYears?.[0]?.id ?? null;
        setActiveAccountingYearIdState(defaultYearId); // Used as loading will be true in this state
      }
    };

    setDefaultAccountingYearIfInvalid(
      companyData?.accountingYears,
      activeAccountingYearId
    );
  }, [companyData, activeAccountingYearId]);

  // Sets company data
  useEffect(() => {
    if (!token || !activeCompanyId) {
      setCompanyData(null);
      setLoadingCompanyData(false);
      return;
    }

    let isMounted = true;
    const fetchCompanyData = async (fetchCompanyId: number) => {
      try {
        setLoadingCompanyData(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/me/company?company_id=${fetchCompanyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!isMounted) return;

        if (!response.ok) {
          if (response.status === 403) {
            setActiveCompanyId(null);
            throw new Error("Unauthorized access to company data");
          }
          throw new Error(`Failed to fetch company data: ${response.status}`);
        }

        const data = await response.json();
        setCompanyData({
          id: data.id,
          name: data.name,
          vatNumber: data.vat_number,
          bankVendorId: data.bank_vendor_id,
          accountingYears: data.accounting_years.map((year: any) => ({
            id: year.id,
            startDate: new Date(year.start_date),
            endDate: new Date(year.end_date),
          })),
          // License data
          stripeCustomerId: data.stripe_customer_id,
          hasAccess: Boolean(data.has_access),
          hasDemoAccess: Boolean(data.has_access_to_demo),
          // Demo data
          demoToken: data.demo_token,
          isDemo: data.is_demo,
        });
      } catch (error) {
        const newError =
          error instanceof Error ? error : new Error("Unknown error occurred");

        setCompanyDataError(newError);
        console.error("Error fetching company data:", error);
      } finally {
        setLoadingCompanyData(false);
      }
    };

    fetchCompanyData(activeCompanyId);

    return () => {
      isMounted = false;
    };
  }, [token, activeCompanyId, setActiveCompanyId]);

  const contextValue = useMemo(
    () => ({
      companyData,
      setCompanyData,
      activeAccountingYearId,
      setActiveAccountingYearId,
      loadingCompanyData,
      activeCompanyId,
      companyDataError,
      setActiveCompanyId,
    }),
    [
      companyData,
      setCompanyData,
      activeAccountingYearId,
      setActiveAccountingYearId,
      loadingCompanyData,
      activeCompanyId,
      setActiveCompanyId,
      companyDataError,
    ]
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

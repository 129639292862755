// src/pages/Dashboard.js
import React from "react";
import { Box, Container, Typography, Grid, Divider, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import FactoryIcon from "@mui/icons-material/FactoryOutlined";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeterOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboardOutlined";
import BarChartIcon from "@mui/icons-material/BarChartOutlined";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";
import { useAuth } from "../hooks/useAuth";
import ESGComponentCard from "../components/ESGComponentCard";
import ESGComponentCardSkeleton from "../components/ESGComponentCardSkeleton";
import MetricCard from "../components/MetricCard";
import ReportButton from "../components/ReportButton";
import AddComppanyButton from "../components/AddCompanyButton";
import { useUserContext } from "../contexts/UserContext";

const Dashboard = () => {
  const { token } = useAuthContext();
  const { isAuthenticated, authenticationIsLoading } = useAuth();

  const { userData, userDataLoading } = useUserContext();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const componentColors = ["#54857e", "#006a61", "#004d46"];
  const [components, setComponents] = useState([]);
  const [scope1, setScope1] = useState(0.0);
  const [scope2, setScope2] = useState(0.0);
  const [turnover, setTurnover] = useState(0.0);
  const [scope1Complete, setScope1Complete] = useState(false);
  const [scope2Complete, setScope2Complete] = useState(false);
  const [turnoverComplete, setTurnoverComplete] = useState(false);

  const {
    companyData,
    activeAccountingYearId,
    loadingCompanyData,
    activeCompanyId,
  } = useCompanyContext();

  const componentAndScopeHeight = "8em";

  const componentCardDataLoaded =
    !loading && Array.isArray(components) && components.length !== 0;

  const reportButtonDataLoaded =
    !loading && (!activeCompanyId || !loadingCompanyData);

  const calculateScopeMetrics = (componentsData) => {
    try {
      // Find E and G components
      const eComponentMetrics = componentsData.find((component) =>
        component.name.startsWith("E")
      )?.metrics;

      const gComponentMetrics = componentsData.find((component) =>
        component.name.startsWith("G")
      )?.metrics;

      // Initialize metrics object
      const metrics = {
        scope1: 0,
        scope2: 0,
        turnover: 0,
      };

      // Calculate E metrics if available
      if (eComponentMetrics) {
        metrics.scope1 = Math.max(0, Number(eComponentMetrics?.scope1) || 0);
        metrics.scope2 = Math.max(0, Number(eComponentMetrics?.scope2) || 0);
      }

      // Calculate turnover if both components are available
      if (gComponentMetrics) {
        const turnoverValue = Math.max(
          0,
          Number(gComponentMetrics?.turnover) || 0
        );
        if (turnoverValue > 0 && (metrics.scope1 > 0 || metrics.scope2 > 0)) {
          const calculatedTurnover =
            (metrics.scope1 + metrics.scope2) / (turnoverValue / 1000000);
          metrics.turnover = Number.isFinite(calculatedTurnover)
            ? calculatedTurnover
            : 0;
        }
      }

      return metrics;
    } catch (error) {
      console.error("Error calculating scope metrics:", error);
      return {
        scope1: 0,
        scope2: 0,
        turnover: 0,
      };
    }
  };

  const transformComponentData = (
    component,
    defaultLogValues,
    activeAccountingYearId
  ) => {
    if (!component) {
      throw new Error("Component is null or undefined");
    }

    // Ensure company_component_logs exists and is an array
    const logs = Array.isArray(component.company_component_logs)
      ? component.company_component_logs
      : [];

    const accountingYearLog = logs.find(
      (log) => log?.accounting_year_id === activeAccountingYearId
    );

    let parsedMetrics = defaultLogValues.metrics;

    if (
      accountingYearLog?.data &&
      typeof accountingYearLog?.data === "string"
    ) {
      try {
        const parsed = JSON.parse(accountingYearLog.data);
        parsedMetrics = {
          scope1:
            typeof parsed.scope1 === "number" ? Math.max(0, parsed.scope1) : 0,
          scope2:
            typeof parsed.scope2 === "number" ? Math.max(parsed.scope2) : 0,
          turnover:
            typeof parsed.turnover === "number" ? Math.max(parsed.turnover) : 0,
        };
      } catch (error) {
        console.error("Error parsing metrics:", error);
      }
    }

    return {
      ...component,
      log: accountingYearLog || defaultLogValues,
      is_completed: Boolean(accountingYearLog?.is_completed),
      metrics: parsedMetrics,
    };
  };

  const getComponentsStatus = (components) => {
    const eComponentLog = components.find((component) =>
      component.name.startsWith("E")
    )?.log;
    const gComponentLog = components.find((component) =>
      component.name.startsWith("G")
    )?.log;

    return {
      scope1Complete: Boolean(eComponentLog?.is_completed),
      scope2Complete: Boolean(eComponentLog?.is_completed),
      turnoverComplete: Boolean(gComponentLog?.is_completed),
    };
  };

  useEffect(() => {
    // Earæy returns
    if (authenticationIsLoading || !isAuthenticated) return;
    if (!activeAccountingYearId || !activeCompanyId) return;

    setLoading(true);

    const fetchUrl = `${process.env.REACT_APP_API_URL}/me/company/components?accounting_year_id=${activeAccountingYearId}&company_id=${activeCompanyId}`;

    const defaultLogValues = {
      metrics: {
        scope1: 0,
        scope2: 0,
        turnover: 0,
      },
      is_completed: false,
      updated_at: undefined,
    };

    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error("Unauthorized");
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error(
            `Expected array of components, received: ${typeof data}`
          );
        }

        const transformedData = data.map((component) =>
          transformComponentData(
            component,
            defaultLogValues,
            activeAccountingYearId
          )
        );
        const metrics = calculateScopeMetrics(transformedData);
        const status = getComponentsStatus(transformedData);

        setComponents(transformedData);
        setScope1(metrics.scope1);
        setScope2(metrics.scope2);
        setTurnover(metrics.turnover);
        setScope1Complete(status.scope1Complete);
        setScope2Complete(status.scope2Complete);
        setTurnoverComplete(status.turnoverComplete);
      })
      .catch((error) => {
        console.error("Error fetching components:", error);
        // Handle specific error cases
        // if (error.message === 'Unauthorized') {
        //   // Handle unauthorized access
        //   navigate('/login');
        // }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    navigate,
    token,
    activeAccountingYearId,
    activeCompanyId,
    isAuthenticated,
    authenticationIsLoading,
  ]);

  const DemoMark = () => {
    return (
      <Chip
        label="Demo"
        sx={{
          backgroundColor: componentColors[0],
          color: "white",
          position: "absolute", // Position it on top of the page
          top: 90, // Adjust spacing from the top
          left: 0, // Adjust spacing from the left
          fontWeight: "bold", // Bold text
          fontSize: "1.1rem", // Adjust font size
          padding: "20px 16px", // Add padding for better appearance
          zIndex: 1000, // Ensure it's on top of other elements
          borderRadius: "0px 4px 4px 0px",
        }}
      />
    );
  };

  if (
    !loading &&
    !loadingCompanyData &&
    !userDataLoading &&
    userData?.companies?.length === 0
  ) {
    return (
      <Container sx={{ my: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            backgroundColor: "background.default", // Optional: Set a background color
          }}
        >
          <AddComppanyButton />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container sx={{ my: 4 }}>
        <Box sx={{ my: 4 }}>
          {companyData?.isDemo ? <DemoMark /> : null}
          <Grid container alignItems="center">
            <Grid item xs={12} md={12} container justifyContent="flex-end">
              <ReportButton
                loading={!reportButtonDataLoaded}
                hasAccess={Boolean(companyData?.hasAccess)}
                hasDemoAccess={Boolean(companyData?.hasDemoAccess)}
                companyData={companyData}
                componentsData={components}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          sx={{
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          {/* Left component column Grid*/}
          <Grid item xs={12} md={6}>
            {/* Column header and description*/}
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <SpaceDashboardIcon
                color="primary"
                sx={{ fontSize: "2rem", mr: 1 }}
              />
              <Typography variant="h4" color="primary" component="h2">
                Udfyld DitESG
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{}}>
              <Typography variant="body1">
                {
                  "Tryk på start herunder for at udfylde E, S og G for det valgte regnskabsår."
                }
              </Typography>
            </Box>

            {!componentCardDataLoaded ? (
              <Grid item xs={12}>
                <ESGComponentCardSkeleton height={componentAndScopeHeight} />
                <ESGComponentCardSkeleton height={componentAndScopeHeight} />
                <ESGComponentCardSkeleton height={componentAndScopeHeight} />
              </Grid>
            ) : (
              components.map((component, index) => {
                const componentName = component.name.split(" - ");
                const companyComponentLog =
                  component.company_component_logs &&
                  component.company_component_logs[0];

                const lastUpdated = companyComponentLog?.updated_at
                  ? new Date(
                      companyComponentLog.updated_at
                    ).toLocaleDateString()
                  : undefined;

                return (
                  // Add this return statement
                  <ESGComponentCard
                    key={index}
                    height={componentAndScopeHeight}
                    avatarColor={
                      componentColors[index % componentColors.length]
                    }
                    avatarLetter={componentName[0]}
                    componentLabel={componentName[1]}
                    lastUpdated={lastUpdated}
                    componentData={component}
                  />
                );
              })
            )}
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 5,
              borderColor: "#88b3af",
              display: { xs: "none", md: "block" },
            }}
          />
          {/* Right scope column Grid*/}
          <Grid item xs={12} md={5} sx={{ mt: { xs: 8, md: 0 } }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Box display="flex" alignItems="center" gap={1}>
                <BarChartIcon color="primary" sx={{ fontSize: "2rem" }} />
                <Typography variant="h4" color="primary" component="h2">
                  Resultater
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1">
              {`Tryk på ”vis rapport” når E, S og G er indtastet.`}
            </Typography>

            <Grid item xs={12} key="scope1">
              <MetricCard
                icon={FactoryIcon}
                title={"Scope 1 - Direkte emissioner"}
                isComplete={scope1Complete}
                incompleteMessage={"Udfør Environment opgave"}
                metricValue={scope1}
                metricUnit={"Tons CO₂e"}
                height={componentAndScopeHeight}
              />
            </Grid>

            <Grid item xs={12} key="scope2">
              <MetricCard
                icon={ElectricMeterIcon}
                title={"Scope 2 - Indirekte emissioner"}
                isComplete={scope2Complete}
                incompleteMessage={"Udfør Environment opgave"}
                metricValue={scope2}
                metricUnit={"Tons CO₂e"}
                height={componentAndScopeHeight}
              />
            </Grid>

            <Grid item xs={12} key="turnover">
              <MetricCard
                icon={AccountBalanceIcon}
                title={"Tons CO₂e per omsat million"}
                isComplete={turnoverComplete}
                incompleteMessage={"Udfyld omsætning under Governance"}
                metricValue={turnover}
                metricUnit={"Tons CO₂e"}
                height={componentAndScopeHeight}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;

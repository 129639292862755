import React, { useState, useEffect } from "react";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const SelectProduct = () => {
  const { token } = useAuthContext();
  const { showSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = useState(false);

  const { activeCompanyId } = useCompanyContext();

  useEffect(() => {
    const fetchExtensionStatus = async () => {
      try {
        if (!token || !activeCompanyId) return;

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/me/company/extension?company_id=${activeCompanyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch extension status");
        }

        const data = await response.json();
        setIsChecked(data.extension_enabled);
      } catch (error) {
        console.error("Error fetching extension status:", error);
      }
    };

    fetchExtensionStatus();
  }, [token, showSnackbar, activeCompanyId]);

  const handleChange = async (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company/extension`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            company_id: activeCompanyId,
            extension_enabled: checked,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update extension status");
      }
    } catch (error) {
      console.error("Error updating extension status:", error);
      showSnackbar("Fejl ved indstilling, prøv igen senere", "error");
    }
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch checked={isChecked} onChange={handleChange} color="primary" />
        }
        label={
          isChecked
            ? "Basis ESG-rapport aktiveret"
            : "Nøgletalsrapport aktiveret"
        }
      />
    </div>
  );
};

export default SelectProduct;

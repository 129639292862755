import { FlexSetting } from "../types/commonTypes";

type FlexSettingValueMap = {
  string: string;
  int: number;
  float: number;
  boolean: boolean;
  date: string | Date;
};

export function geFlexSettingValue<T extends keyof FlexSettingValueMap>(
  settings: FlexSetting[],
  key: string,
  type: T
): FlexSettingValueMap[T] | null {
  const setting = settings?.find(
    (s) => s.setting_key === key && s.setting_type === type
  );

  if (!setting) return null;

  switch (type) {
    case "string":
      return setting.value_string as FlexSettingValueMap[T];
    case "int":
      return setting.value_int as FlexSettingValueMap[T];
    case "float":
      return setting.value_float as FlexSettingValueMap[T];
    case "boolean":
      return setting.value_boolean as FlexSettingValueMap[T];
    case "date":
      return setting.value_date as FlexSettingValueMap[T];
    default:
      return null;
  }
}

// AuthContext.js
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from "react";

export interface AuthContextType {
  token: string | null;
  setToken: (newToken: string | null) => void;
  reRenderToken: () => void;
  authError: string | null;
  login: (credentials: {
    email: string;
    password: string;
  }) => Promise<{ success: boolean; user?: any; error?: string }>;
  logout: () => void;
  authIsLoading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  token: null,
  setToken: () => {}, // No-op function as a placeholder
  reRenderToken: () => {}, // No-op function as a placeholder
  authError: null,
  login: async () => ({ success: false, error: "Not implemented" }), // Placeholder async function
  logout: () => {}, // No-op function as a placeholder
  authIsLoading: true, // Default to true
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setTokenState] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [authIsLoading, setAuthIsLoading] = useState<boolean>(true);

  const setToken = useCallback((newToken: string | null) => {
    if (newToken === null) {
      localStorage.removeItem("token");
    } else {
      localStorage.setItem("token", newToken);
    }
    setTokenState(newToken);
  }, []);

  const reRenderToken = useCallback(() => {
    setTokenState(null); // Temporarily set token to null
    setTimeout(() => {
      setTokenState((prevToken) => localStorage.getItem("token")); // Re-set the token after a short delay
    }, 0); // Use a delay of 0 to ensure React processes the state change
  }, []);

  useEffect(() => {
    let savedToken = localStorage.getItem("token");

    setToken(savedToken);

    setAuthIsLoading(false);
  }, [setToken]);

  // Auth-specific methods
  const login = async (credentials: { email: string; password: string }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (data.token) {
        setToken(data.token);
        setAuthError(null);
        // Optional: Store token in secure storage
        return { success: true, user: data.user };
      } else if (!response.ok && data?.message) {
        setAuthError(data.message);
        return { success: false };
      } else {
        setAuthError("Login mislykkede");
        return { success: false };
      }
    } catch (error: unknown) {
      let errorMessage = "An unexpected error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }
      console.error(error);
      setAuthError(errorMessage);

      return { success: false, error: errorMessage };
    }
  };

  const logout = useCallback(() => {
    setToken(null);
  }, [setToken]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        reRenderToken,
        authError,
        login,
        logout,
        authIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

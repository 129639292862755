import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";
import { SvgIconComponent } from "@mui/icons-material";
import DataUsageIcon from "@mui/icons-material/DataUsage";

interface MetricCardProps {
  icon: SvgIconComponent;
  title: string;
  isComplete: boolean;
  incompleteMessage: string;
  metricValue: number;
  metricUnit: string;
  height: string | number;
}

const MetricCard = (userProps: MetricCardProps): JSX.Element => {
  const defaultProps: MetricCardProps = {
    icon: DataUsageIcon,
    title: "-",
    isComplete: false,
    incompleteMessage: "-",
    metricValue: 0,
    metricUnit: "-",
    height: "0px",
  };

  const props: MetricCardProps = { ...defaultProps, ...userProps };

  const IconComponent = props.icon;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 4,
        height: props.height,
        backgroundColor:
          props.metricValue === 0 ? "transparent" : "rgba(76, 181, 143, 0.3)",
      }}
    >
      <Box sx={{ width: "auto" }}>
        <IconComponent color="primary" sx={{ mr: 1, fontSize: "4rem" }} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Typography variant="h5" component="h2" color="primary">
            {props.title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FunctionsIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1">
            {props.isComplete ? (
              <>
                <strong>
                  {(props.metricValue / 1000).toLocaleString("da-DK", {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })}
                </strong>{" "}
                <span style={{ opacity: 0.7, fontSize: "0.8em" }}>
                  {props.metricUnit}
                </span>
              </>
            ) : (
              props.incompleteMessage
            )}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default MetricCard;
